/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import "../node_modules/bootstrap/scss/bootstrap";

@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

// font-family: 'Montserrat', sans-serif;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
/* scrollbar starts on line 31 */


  h1 {
    margin: 0;
    text-align: center;
  }

  /* custom scrollbar */
  ::-webkit-scrollbar {
    width: 20px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }

/* =============
   General
============= */

html {
  position: relative;
  min-height: 100%;
}

@media (min-width: 350px) {
.h1,.h2,.h3,.h4,.h5,.h6,
h1,h2,h3,h4,h5,h6 {
  color: #000;
  font-size: 13px !important;
}
}

@media (max-width: 350px) {
  .h1,.h2,.h3,.h4,.h5,.h6,
  h1,h2,h3,h4,h5,h6 {
    color: #000;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
  }
}

a {
  text-decoration: none !important;
}

label {
  font-weight: 500;
}

